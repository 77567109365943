import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {UserDTO} from '../../model/dto/user.dto';
import {map} from 'rxjs/operators';
import {SimpleTextFilter} from '../../model/filter/simple-text-filter.model';
import {getMobileToken} from 'src/app/utils/utils.static';

@Injectable({
  providedIn: 'root'
})
export class SearchWebservice {
  private readonly root = `${environment.envApiUrl}/search`;

  private readonly httpMobile: HttpClient;

  constructor(private readonly http: HttpClient, private readonly httpBackend: HttpBackend) {
    this.httpMobile = new HttpClient(httpBackend);
  }

  getAllUsers(): Observable<Array<UserDTO>> {
    return this.http.get(`${this.root}/users`).pipe(map((value) => value as Array<UserDTO>));
  }

  getAllPlaces() {
    return this.http.get(`${this.root}/places`);
  }

  getAllPlacesActive() {
    return this.http.get(`${this.root}/places/active`);
  }

  getAllSimplePlacesForActiveUser() {
    return this.http.get(`${this.root}/places/simple`);
  }

  getAllOrigins() {
    return this.http.get(`${this.root}/origine`);
  }

  getPlacesForCurrentUser() {
    return this.http.get(`${this.root}/user/places`);
  }

  getActivePlacesForCurrentUser() {
    const token = getMobileToken();
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.httpMobile.get(`${this.root}/user/places/active`, options);
    } else {
      return this.http.get(`${this.root}/user/places/active`);
    }
  }

  getEventTypeByTypeActivite(idTypeActivite: number) {
    return this.http.get(`${this.root}/events/${idTypeActivite}`);
  }

  getEventTypeByTypeActiviteAndL(lIdTypeActivite: number[]) {
    return this.http.post(`${this.root}/events/L`, lIdTypeActivite);
  }

  getEventTypeSportByDate(date: Date) {
    return this.http.post(`${this.root}/events/sport`, date);
  }

  getEventTypeOther(value: SimpleTextFilter, searchYear: number) {
    return this.http.post(`${this.root}/events/other?searchYear=${searchYear}`, value);
  }

  getActivityTypeOther() {
    return this.http.get(`${this.root}/events/activityType/other`);
  }

  getAllJobs() {
    return this.http.get(`${this.root}/jobs`);
  }

  getAddress(value: SimpleTextFilter) {
    return this.http.post(`${this.root}/address`, value);
  }

  getEventTypeJob(value: SimpleTextFilter, searchYear: number) {
    return this.http.post(`${this.root}/events/job?searchYear=${searchYear}`, value);
  }
}
